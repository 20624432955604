<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="py-9 px-md-8 px-0 just">
      <v-card
        :color="$vuetify.theme.dark ? '#1d1d1d' : '#fff'"
        class="rounded-lg mx-auto card-width"
        elevation="7"
      >
        <v-card-text class="pa-3">
          <div>
            <div
              class="ms-0 width-full"
            >
              <v-row class="py-1 px-3">
                <v-col
                  cols="12"
                  class="pa-1 d-flex justify-space-between align-center"
                >
                  <div class="d-flex align-center">
                    <v-avatar
                      :color="dataComputed.facility_avatar ? '' : 'secondary'"
                      :class="dataComputed.facility_avatar ? 'mr-3' : 'v-avatar-light-bg primary--text mr-3'"
                      size="32"
                    >
                      <v-img
                        v-if="dataComputed.facility_avatar"
                        :src="dataComputed.facility_avatar"
                      ></v-img>
                      <span
                        v-else
                        class="font-weight-medium"
                      >{{ avatarText(dataComputed.facility_name) }}</span>
                    </v-avatar>
                    <v-col>
                      <v-row>
                        <span class="text-sm font-weight-bold mb-1">{{ dataComputed.video_date }}</span>
                      </v-row>
                      <v-row>
                        <span class="text-xs">{{ dataComputed.field_name }}</span>
                      </v-row>
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
        <video-player
          v-if="tab === 1"
          ref="refVideoPlayer"
          class="video-player-box"
          :options="playerOptions"
          :playsinline="true"
          custom-event-name="customstatechangedeventname"
          @timeupdate="onPlayerTimeupdate($event)"
          @canplaythrough="onPlayerCanplaythrough($event)"
          @ready="playerReadied"
        ></video-player>

        <v-card-actions class="dense mt-2">
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-space-between my-3"
            >
              <!-- <div class="d-flex align-center">
                <v-icon class="mr-2">
                  {{ icons.mdiStar }}
                </v-icon>
                <span>highlights_count</span>
              </div> -->
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-card-text>

    <!-- <v-card-text>
      <player controls>
        <hls
          :config="hlsConfig"
          version="latest"
        >
          <source
            data-src="https://fanaty-staging.s3.us-west-004.backblazeb2.com/hls/playlists/e6aa7477"
            type="application/x-mpegURL"
          />
        </hls>
      </player>
    </v-card-text> -->
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiMenuDown } from '@mdi/js'
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { required, emailValidator, between, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'

// import { Player, Hls } from '@vime/vue'

export default {
  // components: {
  //   Player,
  //   Hls,
  // },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    tab: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { t } = useUtils()
    const dataComputed = computed(() => props.dataParams)

    const refVideoPlayer = ref(null)
    const player = computed(() => refVideoPlayer.value.player)
    const refVideo = ref(null)
    const initdone = ref(false)

    const hlsConfig = ref({})

    const playerOptions = ref({
      controls: true,
      responsive: true,
      breakpoints: true,
      fill: true,
      autoplay: true,
      muted: true,
      loop: true,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [],
      poster: 'https://fanaty-api-stage.s3.amazonaws.com/no-thumb.jpg',
      notSupportedMessage: 'Este video no está disponible temporalmente, intente nuevamente más tarde.',
      usingPlugin: true,
    })

    const updateVideo = () => {
      playerOptions.value.sources = []
      playerOptions.value.sources.push({
        type: 'video/mp4',
        src: dataComputed.value.link,
      })

      if (dataComputed.value.thumbnail) playerOptions.value.poster = dataComputed.value.thumbnail
      else playerOptions.value.poster = 'https://fanaty-api-stage.s3.amazonaws.com/no-thumb.jpg'
    }

    watch([dataComputed], () => {
      updateVideo()
    })

    onMounted(async () => {
      updateVideo()
    })

    // listen event
    const onPlayerTimeupdate = auxPlayer => {
      if (auxPlayer.currentTime() >= 15 && dataComputed.value && dataComputed.value.video_type === 'H') {
        // initdone.value = false
        // auxPlayer.currentTime(10)
      }
    }

    const onPlayerCanplaythrough = auxPlayer => {
      if (!initdone.value && dataComputed.value && dataComputed.value.video_type === 'H') {
        auxPlayer.currentTime(10)
        initdone.value = true
      }
    }

    // player is ready
    const playerReadied = auxPlayer => {
      if (!initdone.value && dataComputed.value && dataComputed.value.video_type === 'H') {
        auxPlayer.currentTime(10)
        initdone.value = true
      }
    }

    return {
      // data
      refVideoPlayer,
      player,
      refVideo,
      initdone,
      playerOptions,
      hlsConfig,

      // computed
      dataComputed,

      // methods
      avatarText,
      onPlayerTimeupdate,
      onPlayerCanplaythrough,
      playerReadied,
      updateVideo,

      // validations
      required,
      emailValidator,
      between,
      integerValidator,

      // i18n
      t,

      // icons
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiCamera,
        mdiMenuDown,
      },
    }
  },
}
</script>

<style scope>
.video-player-box .video-js {
  width: 100% !important;
  height: 56vh !important;
}

.card-width {
  width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .video-player-box .video-js {
    width: 100% !important;
    height: 35vh !important;
  }

  .card-width {
    width: 100%;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .video-player-box .video-js {
    width: 100% !important;
    height: 35vh !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .video-player-box .video-js {
    width: 100% !important;
    height: 35vh !important;
  }

  .card-width {
    width: 100%;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .video-player-box .video-js {
    width: 100% !important;
    height: 40vh !important;
  }

  .card-width {
    width: 70%;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .video-player-box .video-js {
    width: 100% !important;
    height: 40vh !important;
  }

  .card-width {
    width: 70%;
  }
}
</style>
