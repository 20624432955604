<template>
  <v-card
    id="highlight-form"
  >
    <!-- actions -->
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ t(`videos.${tab.title}`) }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <highlight-general-tab
          :data-params="generalData"
          :data-transaction="transactionData"
          :option="option"
        ></highlight-general-tab>
      </v-tab-item>

      <v-tab-item>
        <highlight-video-tab
          :data-params="generalData"
          :option="option"
          :tab="tab"
        ></highlight-video-tab>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { ref, watch, onMounted } from '@vue/composition-api'
import { mdiTableArrowLeft, mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline } from '@mdi/js'
import { useRouter, formatCurrency } from '@core/utils'
import { getHighlight } from '@api'
import { useUtils } from '@core/libs/i18n'
import useSelectOptions from '@core/utils/useSelectOptions'
import 'video.js/dist/video-js.css'

import HighlightGeneralTab from './HighlightGeneralTab.vue'
import HighlightVideoTab from './HighlightVideoTab.vue'

export default {
  components: {
    HighlightGeneralTab,
    HighlightVideoTab,
  },
  setup() {
    const { route, router } = useRouter()
    const { t } = useUtils()
    const {
      configOrganization,
      configFacility,
    } = useSelectOptions()

    const dataId = ref(null)
    const generalData = ref(null)
    const transactionData = ref(null)
    const option = ref(2)
    const pageData = ref()
    const filterData = ref()

    const tab = ref(0)
    const tabs = ref([
      {
        title: 'general',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
      {
        title: 'highlight',
        icon: mdiLockOpenOutline,
        disabled: false,
        hide: false,
      },
    ])

    const goBack = () => {
      // name: 'views-highlight-list',
      router.replace({
        name: 'views-video-view',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      dataId.value = route.value.params.id
      if (dataId.value) {
        pageData.value = route.value.params.pageParams
        filterData.value = route.value.params.filterParams
        option.value = route.value.params.option || 2
        const resp = await getHighlight(dataId.value)
        if (resp.ok) {
          generalData.value = resp.data
        } else goBack()
      } else goBack()
    })

    return {
      // data
      tab,
      tabs,
      option,
      generalData,
      transactionData,
      pageData,
      filterData,

      // method
      formatCurrency,
      goBack,

      // i18n
      t,

      // icons
      icons: {
        mdiTableArrowLeft,
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>
