<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="4"
            cols="12"
            class="align-start d-flex"
          >
            <v-text-field
              v-model="dataComputed.facility_name"
              class="input-avatar"
              :label="t('facilities.facility')"
              dense
              outlined
              readonly
            >
              <template v-slot:prepend>
                <v-avatar
                  size="36px"
                  class="ma-0"
                >
                  <img
                    alt="Avatar"
                    :src="dataComputed.facility_logo"
                  >
                </v-avatar>
              </template>
            </v-text-field>
          </v-col>

          <v-col
            md="4"
            cols="12"
            class="align-center"
          >
            <v-text-field
              v-model="dataComputed.field_name"
              :label="t('fields.field')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            md="4"
            cols="12"
            class="align-start d-flex"
          >
            <v-text-field
              v-model="dataComputed.name"
              :label="t('fields.name')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            md="3"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.video_date"
              :label="t('videos.date')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.start_time"
              :label="t('transactions.start_time')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.end_time"
              :label="t('transactions.end_time')"
              dense
              outlined
              readonly
            >
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.username"
              :label="t('users.fullname')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <!-- <v-col
            cols="12"
            md="2"
            class="align-center justify-center d-flex"
          >
            <v-chip
              :color="resolveStatusVariant(dataComputed.transaction.status)"
              :class="`${resolveStatusVariant(dataComputed.transaction.status)}--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize justify-center"
              style="width: 100%;"
              readonly
            >
              {{ dataComputed.transaction.status_str }} {{ t('transactions.order') }}
            </v-chip>
          </v-col> -->
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiMenuDown } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required, emailValidator, between, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils'
import { getPaymentById } from '@api'

export default {
  props: {
    option: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    dataTransaction: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const form = ref(null)
    const { router } = useRouter()
    const { t } = useUtils()
    const initData = ref({
      end_time: null,
      facility_address: null,
      facility_avatar: null,
      facility_name: null,
      field_name: null,
      id: null,
      left_thumbnail: null,
      link: null,
      right_thumbnail: null,
      start_time: null,
      status: null,
      status_str: null,
      thumbnail: null,
      transaction: {
        transaction_date: null,
        user: {
          fullname: null,
        },
      },
      video_date: null,
      video_time: null,
      video_time_str: null,
    })

    const dataComputed = computed(() => (props.dataParams ? props.dataParams : initData.value))

    const statusOptions = [
      { text: 'Active', value: 'A' },
      { text: 'Inactive', value: 'I' },
    ]

    const resolveStatusVariant = status => {
      if (status === 'primary') return 'primary'
      if (status === 'N') return 'error'
      if (status === 'secondary') return 'secondary'
      if (status === 'warning') return 'warning'
      if (status === 'F') return 'info'
      if (status === 'P') return 'success'

      return 'primary'
    }

    const resolveStatusVideo = status => {
      if (status === 'primary') return 'primary'
      if (status === 'R') return 'error'
      if (status === 'secondary') return 'secondary'
      if (status === 'P') return 'warning'
      if (status === 'S') return 'info'
      if (status === 'A') return 'success'

      return 'primary'
    }

    const onShow = async item => {
      const response = await getPaymentById(item.transaction.payment.id)
      if (response.ok) {
        const payment = response.data
        router.push({
          name: 'views-payment-preview',
          params: {
            id: item.id,
            dataParams: payment,
            dataVideo: item,
            dataTransaction: props.dataTransaction,
            option: 2,
          },
        })
      }
    }

    return {
      // data
      form,
      statusOptions,

      // computed
      dataComputed,

      // methods
      avatarText,
      resolveStatusVariant,
      resolveStatusVideo,
      onShow,

      // validations
      required,
      emailValidator,
      between,
      integerValidator,

      // i18n
      t,

      // icons
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiCamera,
        mdiMenuDown,
      },
    }
  },
}
</script>

<style lang="scss" scope>
div .v-input__prepend-outer {
  margin: 0px 8px 0px 0px !important;
}
</style>
